import React from 'react';
import integ from '../assets/images/integ.svg';
import steps from '../assets/images/steps.svg';
import { Link } from 'react-router-dom';

const MainSection = () => {
  return (
    <div className='mainselection'>
        <h1 className="main-title">İnkişaf etməniz üçün 5 əsas məqam</h1>
        <div className="main-section-wrapper">
        <div className="main-section">
          <div className="steps">
              <img src={steps} alt="Like Icon"/>
            </div>
          </div>
          <div className="integrator">
            <img src={integ} alt="Like Icon" />
          </div>
        <button className="button"><Link className="button" to="/сontactForm">Sorğu buraxın</Link></button>
      </div>
    </div>

  );
};

export default MainSection;