import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg'

const Header = () => {
    return (
        <header className="header">
            <div className="wrapper">
                <div className="logo">
                    <img src={logo} alt="IMC Logo" />
                </div>
                <nav className="nav">
                    <ul>
                        <li><Link to="/">Haqqımızda</Link></li>
                        <li><Link to="/featuresPage">Xidmətlər</Link></li>
                        <li><Link to="/servicesPage">Əlaqə</Link></li>
                        <li><Link to="/сontactForm">Üstünlüklər</Link></li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};

export default Header;