import React, { useState } from "react";
import like from '../assets/images/like.svg';

const ContactForm = () => {

    const token = "6865369018:AAEa0sixFZYA7TVfbM6nVhRotrhY5cRLZZw";
    const chat_id = "-4267792925";
    const api = `https://api.telegram.org/bot${token}/sendMessage`;

    async function handleSubmit(e) { 
        e.preventDefault(); 

        const form = e.target;
        const formbtn = document.querySelector('#btnone')
        
        const {Name , Phone} = Object.fromEntries(new FormData(form).entries())

        const text = `Заявка от ${Name}!\nТелефон:  ${Phone}`;

        try{

            const response = await fetch(api, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    chat_id, 
                    text, 
                })
              });

              if(response.ok){
                console.log("")
                form.reset();
              }else{
                throw new Error(response.statusText);
              }
    
        } catch (error) {
            console.error(error)
        }

        var x = document.getElementsByClassName('contact-form-container');
        var contentSet = document.getElementsByClassName('feedback-set');
        var i;
        for (i = 0; i < x.length; i++) {
            x[i].style.visibility = 'hidden';
            contentSet[i].style.visibility = 'visible';
            contentSet[i].style.display = 'flex'; 
            x[i].style.display = 'none'; 
        }
    }

    const [text, setText] = useState('');
    const [number, setNamber] = useState('');
    const [checked, setChecked] = useState(false);

    const textChange = (event) => {
        setText(event.target.value);
    }

    const numberChange = (event) => {
        setNamber(event.target.value);
    }

    function handleChange() {
		setChecked(!checked); 
        console.log(checked)
	}

    
    if(text === "" || number === ""){
        let btnhidden = document.getElementsByClassName('btnhidden');
        var j;
        for (j = 0; j < btnhidden.length; j++) {
            btnhidden[j].style.pointerEvents = "none";
        }
    }else{
        let btnhidden = document.getElementsByClassName('btnhidden');
        var j;
        for (j = 0; j < btnhidden.length; j++) {
            btnhidden[j].style.pointerEvents = "all";
            btnhidden[j].style.backgroundColor = "#D1BC7D";
        }
    }
    
    return (
        <div>
            <div className="feedback-set">
                    <div>
                        <h2>Müraciətiniz uğurla göndərildi!</h2>
                    </div>
                    <h4>Menecerimiz lazımi məlumatları dəqiqləşdirmək üçün qısa zamanda sizinlə əlaqə saxlayacaq.</h4>
                    <h3>Zəhmət olmasa Instagramımızı izləyin <a href="https://www.instagram.com/rhalilife/?igsh=MXgzY3Zvb203c2sxdA%3D%3D">@rhalilife</a></h3>
                <img src={like} alt="" />
            </div>
            <div  className="contact-form-container">
                <h1>Pulsuz əlaqə üçün qeydiyyatdan keçin</h1>
                <p>
                    Size uyğun olan əlaqə vasitəsi ilə bizimlə əlaqə saxlayın və ya <br/> bizim sizinlə əlaqə saxlamamız üçün formanı doldurun
                </p>
                <h2>Əlaqə</h2>
                <div className="contact-form">
                    <form onSubmit={handleSubmit} className="feedback-contant-input">
                        <input id="text" type="text" name="Name" onChange={textChange}  value={text} placeholder="Name"/>
                        <input id="number" type="tel" required placeholder="Phone" name="Phone" onChange={numberChange} value={number} />
                        <label>
                            <input type="checkbox" required />
                            Mən məxfilik siyasətinin şərtlərini qəbul edirəm
                        </label>
                        <button id="btnone" variant="success" className="btnhidden" type="submit" >Sorğu buraxın</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;