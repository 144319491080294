import React from 'react';
import logo from '../assets/images/logo.svg';
import mail from '../assets/images/mail.svg';
import call from '../assets/images/call.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="wrapper">
                <div className="footer-logo">
                    <img src={logo} alt="IMC Logo" />
                    <p>İnteqrator media xidmətləri, şirkətlərə və təşkilatlara məzmunlarını daha geniş auditoriyaya çatdırmaq, markalarını gücləndirmək və rəqəmsal məkanda daha çox görünürlük əldə etmək imkanı verir. Bu xidmətlər, həmçinin, daha effektiv və koordinasiyalı marketinq strategiyaları yaratmaq üçün müxtəlif media növlərini və kanallarını birləşdirir.</p>
                    <p><span className='footer-span-color'>© 2024. INTEQRATOR Bütün hüquqlar qorunur.</span></p>
                </div>
                <div className="footer-contact">
                    <h4>Əlaqə</h4>
                    <ul>
                        <li><img src={call} alt="Call Icon" /> +994 51 123 45 67</li>
                        <li><img src={mail} alt="Email Icon" /><a href="mailto:inteqrator@gmail.com">inteqrator@gmail.com</a></li>
                        <li><img src={mail} alt="Website Icon" /><a href="https://www.instagram.com/rhalilife/?igsh=MXgzY3Zvb203c2sxdA%3D%3D">@rhalilife</a></li>
                    </ul>
                </div>
                <div className="footer-nav">
                    <h4>Haqqımızda</h4>
                    <ul>
                        <li><Link to="/featuresPage">Xidmətlər</Link></li>
                        <li><Link to="/сontactForm">Üstünlüklər</Link></li>
                    </ul>
                </div>
            </div>
        </footer>
    );
};

export default Footer;