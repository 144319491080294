import React from 'react';
import like from '../assets/images/like.svg';

const features = [
  'Rəqibləri təhlil edirik (podkastlar yaradıb və önə çıxarırıq)',
  'Biznes videolarını nəzərdən keçiririk (podkastlar yaradıb və önə çıxarırıq)',
  'Biz hədəf auditoriyanı cəlb etmək üçün strateji kontent yaradırıq (podkastlar yaradıb və önə çıxarırıq, satış qıfını avtomatlaşdırırıq)',
  'Hədəf auditoriyasını təhlil edirik',
  'Strateji təhlil aparırıq',
  'Sizin biznesdə olan qiymətləri təhlil edərək'
];

const Features = () => {
  return (
    <div className='features'>
      <div className='features-title-conteiner'>
        <h1 className="features-title">Bizim əməkdaşlığın üstünlükləri:</h1>
      </div>
      <div className="features-wrapper">
      <ul className="features-list">
        {features.map((feature, index) => (
          <li key={index} className="feature-item">
            <div className="feature-icon"><img src={like} alt="Like Icon" /></div>
            <div className="feature-text">{feature}</div>
          </li>
        ))}
      </ul>
    </div>
    </div>
  );
};

export default Features;