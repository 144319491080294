import React from 'react';
import Services from '../components/Services';

function ServicesPage() {
    return (
        <div className="App">
            <Services />
        </div>
    );
}

export default ServicesPage;