import React from 'react';
import Features from '../components/Features';
import MainSection from '../components/MainSection';

function FeaturesPage() {
  return (
    <div className="App">
      <div className="">
        <Features />
        <MainSection />
      </div>
    </div>
  );
}

export default FeaturesPage;