import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import ContactFormPage from './pages/ContactFormPage';
import ServicesPage from './pages/ServicesPage';
import FeaturesPage from './pages/FeaturesPage';
import './App.css';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import avatar from "./assets/images/avatar.webp";

function App() {
    return (
        <Router>
            <div className="App">
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/сontactForm" element={<ContactFormPage />} />
                    <Route path="/servicesPage" element={<ServicesPage />} />
                    <Route path="/featuresPage" element={<FeaturesPage />} />
                </Routes>
                <Footer />
                <FloatingWhatsApp 
                    phoneNumber="994514111948" 
                    accountName="Расим"
                    avatar={avatar}
                    statusMessage=""
                    chatMessage="Salam, hansı xidmətlə maraqlanırsınız?"
                   />
            </div>
        </Router>
    );
}

export default App;