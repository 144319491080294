import React from 'react';


const MainContent = () => {
    return (
        <main className="main-content">
            <section className="welcome-section">
                <h1>“IMC” rəqəmsal marketinq agentliyinə <br/> xoş gəlmisiniz!</h1>
            </section>
            <section className="about-section">
                <h2>Haqqımızda</h2>
                <h3>Niyə məhz biz ?</h3>
                <p>Sizi rəqiblərinizdən fərqləndirən saatlıq filmlər, <br/> podkastlar, və reelslər hazırlayırıq.</p>
                <h3>Sizin uğurunuz</h3>
                <p>Siz biznesinizə sərmayə qoyarkən, biz isə sizə şəxsi<br/> brend qururuq və sizi rəqiblərinizdən bir pillə üstün <br/> edirik!</p>
            </section>
        </main>
    );
};

export default MainContent;