import React from 'react';
import s1 from '../assets/images/s1.svg';
import s2 from '../assets/images/s2.svg';
import s3 from '../assets/images/s3.svg';
import s4 from '../assets/images/s4.svg';
import s5 from '../assets/images/s5.svg';
import s6 from '../assets/images/s6.svg';
import s7 from '../assets/images/s7.svg';
import s8 from '../assets/images/s8.svg';
import s9 from '../assets/images/s9.svg';
import s10 from '../assets/images/s10.svg';

const services = [
    { icon: s1, text: 'İrəliləmenin kompleks online strategiyaları.' },
    { icon: s2, text: 'Foto kontent vasitəsi ilə peşəkar vizual dəstək.' },
    { icon: s3, text: 'Satış üçün reels və shots yaradılması.' },
    { icon: s4, text: 'Öne çıxan baxışlar üçün peşəkar video çəkilişlər və prodakşnlar.' },
    { icon: s5, text: 'Auditoriyanı cəlb edən podkastların yaradılması və irəliləyişi.' },
    { icon: s6, text: 'Şirkətin dəyərlərini vurğulayan unikal mətnlər.' },
    { icon: s7, text: 'Unikal və keyfiyyətli kontentin yaradılması.' },
    { icon: s8, text: 'Onlayn məkan və biznes.' },
    { icon: s9, text: 'SMM: onlayn iştirakın strateji idarə edilməsi' },
    { icon: s10, text: 'Diqqəti cəlb etmək və konversiyanı artırmaq üçün strategiyalar.' }
];

const Services = () => {
    return (
        <div className="services-container">
            <div className='services-banner'>
                <h1>Xidmətlərimiz</h1>
            </div>
            <div className='wrapper-services'>
                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-card">
                            <div className="service-icon">
                                <img src={service.icon} alt={`Service ${index + 1}`} />
                            </div>
                            <p>{service.text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Services;